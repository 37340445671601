import React from 'react'
import { Observer, inject, observer } from 'mobx-react'
import { RecipesStore } from '../../store/RecipesStore'
import {
  IonList,
  IonLabel,
  IonItem,
  IonInput,
  IonCol,
  IonGrid,
  IonButton,
  IonIcon,
  IonButtons,
  IonRow,
  IonTextarea,
} from '@ionic/react'
import { calendarOutline, open } from 'ionicons/icons'
import ImageButtons from './ImageButtons'
import ImagesGrid from '../../../storage-files/views/ImagesGrid'
import ImagesViewer from '../../../storage-files/views/ImagesViewer'
import BotSessionEditModal from '../../../bot-sessions/views/edit/BotSessionEditModal'
import MealsListModal from '../../../meals/views/list/MealsListModal'

interface Props {
  recipesStore?: RecipesStore
}

const DetailsForm: React.FC<Props> = ({ recipesStore }) => {
  const { editVM } = recipesStore
  if (!editVM) return null
  if (editVM.currentTabIndex !== '0') return null

  const renderName = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Name</IonLabel>
        <IonInput
          type='text'
          value={editVM.name}
          onIonChange={(e: any) => editVM.setName(e.target.value)}
          ref={(e) => editVM.disableAutoComplete(e)}
        />
      </IonItem>
    )
  }

  const renderLink = () => {
    return (
      <IonGrid>
        <IonRow>
          <IonCol sizeXs='10'>
            <IonItem lines={'inset'}>
              <IonLabel position='floating'>Recipe Link</IonLabel>
              <IonTextarea
                autoGrow
                value={editVM.linkUrl}
                onIonChange={(e: any) => editVM.setLinkUrl(e.target.value)}
                ref={(e) => editVM.disableAutoComplete(e)}
              />
            </IonItem>
          </IonCol>
          <IonCol sizeXs='2'>
            <IonButton size='default' color='light' onClick={() => editVM.openLink()}>
              <IonIcon icon={open} />
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    )
  }

  const renderNotes = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Description</IonLabel>
        <IonTextarea
          value={editVM.notes}
          onIonChange={(e: any) => editVM.setNotes(e.target.value)}
          // ref={e => editVM.disableAutoComplete(e)}
          rows={4}
          autoGrow
        />
      </IonItem>
    )
  }

  const renderImageButtons = () => {
    return <ImageButtons />
  }

  const renderImagesGrid = () => {
    return (
      <Observer>
        {() => (
          <ImagesGrid
            storageFiles={editVM.storageFiles}
            sortable={false}
            onImageClick={(index) => editVM.openImagesViewer(index)}
            onSortEnd={(oldIdx, newIdx) => editVM.sortAttachments(oldIdx, newIdx)}
          />
        )}
      </Observer>
    )
  }

  const renderImagesViewer = () => {
    return (
      <Observer>
        {() => (
          <ImagesViewer
            key={editVM.recipeGuid + '_iv' + editVM.storageFiles.length}
            storageFiles={editVM.storageFiles}
            isOpen={editVM.imagesViewerShown}
            onClose={() => editVM.closeImagesViewer()}
            initialIndex={editVM.imagesViewerIndex}
          />
        )}
      </Observer>
    )
  }

  const renderGenerateImageModal = () => {
    return (
      <Observer>
        {() => (
          <BotSessionEditModal
            botName='generate-recipe-image-v1'
            shown={editVM.generateImageModalShown}
            onHide={() => editVM.closeGenerateImageModal()}
            title={'Generate Recipe Images'}
            description={editVM.recipeDescription}
            parentRecordTable='recipes.Recipe'
            parentRecordGuid={editVM.recipeGuid}
          />
        )}
      </Observer>
    )
  }

  const renderAddToPlannerButton = () => {
    return (
      <div className='buttons'>
        <IonButton color='tertiary' onClick={() => editVM.openMealsListModal()} size='large'>
          <IonIcon icon={calendarOutline} size='small' />
          &nbsp;Add To Planner
        </IonButton>
      </div>
    )
  }

  const renderMealsListModal = () => {
    return (
      <Observer>
        {() => (
          <MealsListModal
            botName='generate-recipe-image-v1'
            shown={editVM.mealsListModalShown}
            onHide={() => editVM.closeMealsListModal()}
            title={'Add to Planner'}
            description={editVM.recipeDescription}
            parentRecordTable='recipes.Recipe'
            parentRecordGuid={editVM.recipeGuid}
          />
        )}
      </Observer>
    )
  }

  return (
    <div id='DetailsForm'>
      <form autoComplete='off'>
        <IonList>
          {renderName()}
          {renderLink()}
          {renderNotes()}
          {renderImagesGrid()}
          {renderImagesViewer()}
          {renderImageButtons()}
          {renderGenerateImageModal()}
          <br />
          <br />
          {renderAddToPlannerButton()}
          {renderMealsListModal()}
        </IonList>
      </form>
    </div>
  )
}

export default inject('recipesStore')(observer(DetailsForm))
